import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Navbar from '../components/navbar';
import SEO from '../components/seo';
import {config} from '../config';

const Contact = () => {
    const [email, setEmail] = React.useState('')
    const [name, setName] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [errors, setErrors] = React.useState({})

    const { executeRecaptcha } = useGoogleReCaptcha()

    const isFormValid = () => {
        if(email === '' || name === '' || message === '') {
            return false
        }
        else {
            return true
        }
    }

    const handleSubmit  = async(e, email, name, message) => {
        e.preventDefault();
        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            console.log('in here')
            return
        }

        const recaptchaResult = await executeRecaptcha('contact')
        const payload = {
            email:email,
            name:name,
            message:message,
            token:recaptchaResult
        }
        await fetch(
            `${config.url.API_URL}contact`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }
        ).then(response => {
            response.json().then(data =>{
                if(!response.ok){
                    setErrors(data)
                }
                else {
                    setName('');
                    setEmail('');
                    setMessage('');                    
                }                
            })
        })
    }

    return (
        <div>
            <SEO 
                title="Contact"
                description="Contact Tacent"
            /> 
            <Container style={{padding: '20px'}} maxWidth='lg'>
                <Navbar/>
                <h1>Contact Us Today</h1>
                <Grid 
                    component="form"
                    container
                    spacing={2}
                    direction='column'
                    onSubmit={(e) => handleSubmit(e, email, name, message)}
                >
                    <Grid item>
                        <TextField 
                            autoComplete='off' 
                            fullWidth id="outlined-basic" 
                            label="Email" 
                            variant="outlined"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            autoFocus
                        />                    
                    </Grid>
                    <Grid item>
                        <TextField 
                            autoComplete='off' 
                            fullWidth id="outlined-basic" 
                            label="Name" 
                            variant="outlined"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />                    
                    </Grid>
                    <Grid item>
                        <TextField 
                            autoComplete='off' 
                            fullWidth id="outlined-basic" 
                            label="Message" 
                            variant="outlined"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            multiline
                            rows={4}
                        />                    
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!isFormValid()}
                            color='primary'
                            variant='contained'
                            onClick={(e) => handleSubmit(e, email, name, message)}
                            type='submit'
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Contact;
