const getConfig = () => {
    const prod = {
        url: {
            API_URL: 'https://api.tacent.io/api/v1/',
        },
    };
    
    const stag = {
        url: {
            API_URL: 'https://app.tacent.io/api/v1/',
        },
    };
    
    const dev = {
        url: {
            API_URL: 'http://localhost:80/api/v1/',
        },
    };

    if (process.env.NODE_ENV === 'staging') {
        return stag;
    }

    if (process.env.NODE_ENV === 'development') {
        console.log('inf dev')
        return dev;
    }
    
    if (process.env.NODE_ENV === 'production') {
        return prod;
    }
}

const config = getConfig()

export {config}